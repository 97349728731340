import _ from 'lodash';
import * as copilot from '@janus.team/janus-copilot';
import particles from '@janus.team/janus-particles';
import * as config from 'config/index.js';

const rackerCatalogOverrides = (input) => {
  const inputWithExtraUtilityNavigationItems = _.reduce([
    [copilot.mergeUtilityNavigationLinksByRel, {
      'solutions-dashboard': { active: true }
    }]
  ], (catalog, [fn, link]) => fn(catalog, link), input);
  return inputWithExtraUtilityNavigationItems;
};

const customerCatalogOverrides = (input) => {
  _.set(input, ['catalog', 'catalog', 'primaryNavigation', 'links'], []);
  return input;
};

const audience = (input) => _.get(input, 'catalog.catalog.meta.type') || 'customer';
const override = (input) => _.get({
  racker: rackerCatalogOverrides,
  customer: customerCatalogOverrides
}, audience(input));

const catalogOverrides = (catalog) => override(catalog)(catalog);

export const copilotConfiguration = (extras = {}) => ({
  catalogOverrides,
  racker: config.isRacker(),
  useJanusLogin: true,
  onInvalidCatalog: _.noop,
  onCatalogError: _.noop,
  onMissingCatalog: _.noop,
  baseUrl: config.baseUrl(),
  featureFlags: { responsive: !(/responsive=false/i).test(`${global.location.search}`) },
  locale: particles.translations.resolveLocale(),
  pilotOptions: { feedback_enabled: '1' },
  credentials: config.isLocal() ? 'include' : 'same-origin',
  csrfCookieName: config.isLocal() ? 'CSRF_TOKEN_SUBDOMAIN' : 'CSRF_TOKEN',
  janusLoginServerCsrfCookieName: _.get({
    local: '__janus_login_csurf_dev_subdomain__',
    dev: '__janus_login_csurf_dev__',
    prod: '__janus_login_csurf_prod__'
  }, config.env()),
  ...extras
});
