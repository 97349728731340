import React from 'react';
import { Card, Grid, KeyValuePair } from '@janus.team/janus-particles';

const CardViewSkeleton = () => {
  const header = <span className="rsd-skeleton__placeholder">This is an Account Name</span>;

  return (
    <Grid withGutters>
      {[1, 2, 3, 4, 5, 6].map((skeleton) => (
        <Grid.Cell xs={12} sm={6} md={4} verticalGutter key={skeleton}>
          <Card header={header} inGrid={true} className="rsd-skeleton">
            <KeyValuePair vertical>
              <span>ID</span>
              <span className="rsd-skeleton__placeholder">b4293e55-8703-4874-977c-947db4b5f8e7</span>
            </KeyValuePair>
            <KeyValuePair vertical>
              <span>Service Blocks</span>
              <span className="rsd-skeleton__placeholder">Platform Essentials, Manage & Operate</span>
            </KeyValuePair>
          </Card>
        </Grid.Cell>
      ))}
    </Grid>
  );
};

export default CardViewSkeleton;
