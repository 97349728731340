import _ from 'lodash';
import { proxyFetch } from 'data/proxy.js';

const accountIdForTenantId = (tenantId) => {
  return _.split(tenantId, ':')[1] || tenantId;
};

export const searchCustomers = (key, { search, filters = [] }) => {
  const query = new URLSearchParams();
  query.set('q', search);
  query.set('type', 'account');
  query.set('prefix_match', 'true');
  filters.forEach((filter) => query.append('filter', filter));

  const url = `https://proxy.api.manage.rackspace.com/encore/api/global-search/search?${query.toString()}`;

  return proxyFetch(url, { headers: { Accept: 'application/json' } })
    .then(_.method('parseJson'))
    .then(_.property('results'))
    .then((results) => {
      return _.map(results, (result) => {
        return {
          ...result,
          id: accountIdForTenantId(result.tenant_id),
          displayName: result?.domain_account?.name || result?.name,
          displayDomain: result?.domain_account?.domain || result?.domain
        };
      });
    });
};
