import _ from 'lodash';
import React, { useState } from 'react';
import { useLocation, useHistory, useParams, Link, useRouteMatch } from 'react-router-dom';
import { Page } from '@janus.team/janus-particles';
import { t } from 'translations/index.js';
import * as b from 'react-bootstrap';
import { context as janusContext } from '@janus.team/janus-copilot/cjs/janus-copilot.js';
import { isRacker } from 'config/index.js';

import AccountCardView from 'customer/account-card-view.js';
import AccountTableView from 'customer/account-table-view.js';
import useQueryParameters from 'hooks/use-query-parameters.js';
import { useViewTypePreference } from 'preferences/index.js';
import { useQueryListCustomerAccountsForDomain, useQueryGetGCPAccessGrants } from 'customer/queries.js';
import * as customer from 'customer/data.js';
import DefaultSearchParams from 'search/default-search-params';
import MagnifyingGlass from '@rss-engineering/fabric-assets/dist/icons/MagnifyingGlass';
import Grid from '@rss-engineering/fabric-assets/dist/icons/Grid';
import List from '@rss-engineering/fabric-assets/dist/icons/List';
import SlidersSimple from '@rss-engineering/fabric-assets/dist/icons/SlidersSimple';
import { getURL, isOptionDisabled } from 'customer';
import CONSTANTS from 'common/constants';

const mergeAccessGrants = (accounts, accessGrants) => {
  return _.map(accounts, (account) => {
    if (account.type === 'MANAGED_GCP') {
      return _.merge(account, { accessGrants: _.filter(accessGrants, { projectId: account.id }) });
    }
    return account;
  });
};

const filterDataBySearch = (data = [], search = '') => {
  return data.filter((account) => {
    const fields = ['id', 'name'];
    const query = _.lowerCase(search);

    return _.some(fields, (field) => _.includes(_.lowerCase(_.get(account, field)), query));
  });
};

const SearchForm = ({ search }) => {
  const history = useHistory();
  const queryString = useLocation()?.search;
  const queryParameters = new URLSearchParams(queryString);
  const [inputValue, setInputValue] = React.useState(search);

  const handleChange = ({ target: { value } }) => setInputValue(value);
  const handleSubmit = (event) => {
    event.preventDefault();
    queryParameters.set('search', inputValue);
    window.DD_RUM.onReady(function() {
      window.DD_RUM.addAction('searchValue', {
        value: inputValue
      });
    });
    history.push({ search: `?${queryParameters.toString()}` });
  };

  return (
    <b.Form onSubmit={handleSubmit} className="">
      <b.InputGroup className="">
        <b.FormControl
          size="md"
          value={inputValue}
          onChange={handleChange}
          placeholder={t('Search Accounts')}
          aria-label={t('Search Accounts')}
        />
        <b.Button size="md" type="submit" variant="outline-primary"><MagnifyingGlass size={20} /></b.Button>
      </b.InputGroup>
    </b.Form>
  );
};

const useAccountsMatchingSearch = () => {
  const queryParameters = useQueryParameters();
  const search = queryParameters.get('search') || '';
  const { domain } = useParams();
  const { query } = useQueryListCustomerAccountsForDomain(domain);

  const accounts = customer.filterOspcFromCustomerAccounts(query?.data);
  const mappedAccountData = React.useMemo(() => customer.mapAccountStatus(accounts), [accounts]);
  return React.useMemo(() => ({ accounts: filterDataBySearch(mappedAccountData, search), search, query }), [mappedAccountData, search, query]);
};

const useAccountTypes = () => {
  const { accounts } = useAccountsMatchingSearch();
  return React.useMemo(() => {
    const byType = _.groupBy(_.sortBy(accounts, customer.accountTypeDescriptorForAccount), 'type');
    return _.map(byType, (accounts, type) => {
      return {
        type,
        count: accounts?.length,
        label: customer.accountTypeDescriptorForAccount({ type }),
        accounts
      };
    });
  }, [accounts]);
};

const isAccountTypeSelected = ({ type, search }) => {
  const queryParameters = new URLSearchParams(search);
  return _.includes(_.split(queryParameters.get('types'), ','), type);
};

const AccountTypes = ({ setSelectedAccountTypes }) => {
  const accountTypes = useAccountTypes();
  const { url } = useRouteMatch();
  const search = useLocation()?.search;
  const queryParameters = new URLSearchParams(search);
  const previousSelectedTypesQuery = _.compact(_.split(queryParameters.get('types'), ','));
  const allQueryParameters = new URLSearchParams(search);
  allQueryParameters.set('types', '');

  const [hoverClassApplied, setHoverClassApplied] = useState([]);

  const handleHover = (index) => {
    setHoverClassApplied((prevHoverStates) => {
      const newHoverStates = [...prevHoverStates];
      newHoverStates[index] = true;
      return newHoverStates;
    });
  };

  const handleLeave = (index) => {
    setHoverClassApplied((prevHoverStates) => {
      const newHoverStates = [...prevHoverStates];
      newHoverStates[index] = false;
      return newHoverStates;
    });
  };

  return (
    <div className="d-flex flex-column rsd-account-type-facets__container">
      {_.map(accountTypes, ({ type, count, label }, index) => {
        const isSelected = isAccountTypeSelected({ type, search });
        const newQueryParameters = new URLSearchParams(search);
        const newSelectedTypesQuery = _.uniq(isSelected ? _.without(previousSelectedTypesQuery, type) : _.concat(previousSelectedTypesQuery, [type]));
        newQueryParameters.set('types', newSelectedTypesQuery);
        return (
          <b.Dropdown.Item key={type} as={Link} to={`${url}?${newQueryParameters.toString()}`}
            className={isSelected ? 'selected' : ''}
            onMouseEnter={() => handleHover(index)}
            onMouseLeave={() => handleLeave(index)}
          >
            <b.FormCheck
              checked={isSelected || hoverClassApplied[index] ? true : false}
              className={`mb-1 rsd-account-type-facets__facet rsd-account-type-facets__facet--${isSelected ? '' : 'not-'}selected`}
              id={'checkbox_' + index}
              label={label + ` (${count})`}
              type="checkbox"
            />
          </b.Dropdown.Item>
        );
      })}
    </div>
  );
};

const useAccountTypeFilters = () => {
  const search = useLocation()?.search;
  return React.useMemo(() => {
    const queryParameters = new URLSearchParams(search);
    const selectedAccountTypes = _.compact(_.split(queryParameters.get('types'), ','));
    return { selectedAccountTypes, search };
  }, [search]);
};

const useAccountsMatchingSelectedAccountType = ({ accounts }) => {
  const { selectedAccountTypes, search } = useAccountTypeFilters();
  return React.useMemo(() => ({
    accounts: _.filter(accounts, (account) => !_.some(selectedAccountTypes, _.identity) || isAccountTypeSelected({ search, type: account?.type }))
  }), [accounts, selectedAccountTypes, search]);
};

const isAccountStatusSelected = ({ status, search }) => {
  const queryParameters = new URLSearchParams(search);
  return _.includes(_.split(queryParameters.get('status'), ','), status);
};

const useAccountStatuses = () => {
  const { accounts } = useAccountsMatchingSearch();
  return React.useMemo(() => {
    const byStatus = _.groupBy(_.sortBy(accounts), 'mappedStatus');
    return _.map(byStatus, (accounts, status) => {
      return {
        status,
        count: accounts?.length,
        label: status,
        accounts
      };
    });
  }, [accounts]);
};

const AccountStatuses = ({ setSelectedAccountStatuses }) => {
  const accountStatuses = useAccountStatuses();
  const { url } = useRouteMatch();
  const search = useLocation()?.search;
  const queryParameters = new URLSearchParams(search);
  const previousSelectedStatusesQuery = _.compact(_.split(queryParameters.get('status'), ','));
  const allQueryParameters = new URLSearchParams(search);
  allQueryParameters.set('status', '');

  const [hoverClassAppliedStatus, setHoverClassAppliedStatus] = useState([]);

  const handleHoverStatus = (index) => {
    setHoverClassAppliedStatus((prevHoverStates) => {
      const newHoverStates = [...prevHoverStates];
      newHoverStates[index] = true;
      return newHoverStates;
    });
  };

  const handleLeaveStatus = (index) => {
    setHoverClassAppliedStatus((prevHoverStates) => {
      const newHoverStates = [...prevHoverStates];
      newHoverStates[index] = false;
      return newHoverStates;
    });
  };

  return (
    <div className="d-flex flex-column rsd-account-status-facets__container">
      {_.map(accountStatuses, ({ status, count, label }, index) => {
        const isSelected = isAccountStatusSelected({ status, search });
        const newQueryParameters = new URLSearchParams(search);
        const newSelectedTypesQuery = _.uniq(isSelected ? _.without(previousSelectedStatusesQuery, status) : _.concat(previousSelectedStatusesQuery, [status]));
        newQueryParameters.set('status', newSelectedTypesQuery);
        return (
          <b.Dropdown.Item key={status} as={Link} to={`${url}?${newQueryParameters.toString()}`}
            className={isSelected ? 'selected' : ''}
            onMouseEnter={() => handleHoverStatus(index)}
            onMouseLeave={() => handleLeaveStatus(index)}
          >
            <b.FormCheck
              checked={isSelected || hoverClassAppliedStatus[index] ? true : false}
              className={`mb-1 rsd-account-status-facets__facet rsd-account-status-facets__facet--${isSelected ? '' : 'not-'}selected`}
              id={'checkbox_' + index}
              label={label + ` (${count})`}
              type="checkbox"
            />
          </b.Dropdown.Item>
        );
      })}
    </div>
  );
};

const useAccountsMatchingSelectedAccountStatus = ({ accounts }) => {
  const search = useLocation()?.search;
  return React.useMemo(() => {
    const queryParameters = new URLSearchParams(search);
    const selectedAccountStatuses = _.compact(_.split(queryParameters.get('status'), ','));
    return {
      accounts: _.filter(accounts, (account) => !_.some(selectedAccountStatuses, _.identity) || isAccountStatusSelected({ search, status: account?.mappedStatus }))
    };
  }, [accounts, search]);
};

const FilterTrigger = ({ allAccounts, filteredAccounts }) => {
  const allAccountsCount = allAccounts?.length;
  const filteredAccountsCount = filteredAccounts?.length;
  const filtersApplied = filteredAccountsCount !== allAccountsCount;
  return (
    <b.Dropdown>
      <b.Dropdown.Toggle
        size="md"
        variant="outline-primary"
        className={`rsd-account-list-filter-dropdown-trigger ${filtersApplied ? 'filters-applied' : ''}`}
        id="account-filter-dropdown"
      >
        <SlidersSimple size={20} /> Filter
      </b.Dropdown.Toggle>
      <b.Dropdown.Menu align="right" className="rsd-account-list-filter-dropdown-menu">
        <b.Dropdown.Header>Type</b.Dropdown.Header>
        <AccountTypes />
        <b.Dropdown.Divider />
        <b.Dropdown.Header>Status</b.Dropdown.Header>
        <AccountStatuses />
      </b.Dropdown.Menu>
    </b.Dropdown>
  );
};

const handleAddAccountTrigger = (accountType) => {
  window.DD_RUM.onReady(function() {
    window.DD_RUM.addAction('add-account', {
      accountTypeAdded: accountType.name
    });
  });
};

const AddAccountTrigger = ({ addAccountTypes, domainId, loginType, roles }) => {
  return (
    <b.Dropdown>
      <b.Dropdown.Toggle variant="primary" id="dropdown-basic">
        Add/Link Account
      </b.Dropdown.Toggle>
      <b.Dropdown.Menu>
        {
          addAccountTypes.map((accountType) => {
            return (
              <b.Dropdown.Item
                disabled={isOptionDisabled(loginType, accountType, roles)}
                key={accountType.name}
                href={getURL(accountType, domainId)}
                onClick={() =>handleAddAccountTrigger(accountType)}
              >
                <span>
                  {accountType.name}
                </span>
              </b.Dropdown.Item>
            );
          })
        }
      </b.Dropdown.Menu>
    </b.Dropdown>
  );
};

const AccountList = () => {
  const views = { 'card': AccountCardView, 'table': AccountTableView };
  const [ViewComponent, setViewComponentType] = useViewTypePreference(views, AccountCardView);
  const { session } = React.useContext(janusContext.session);
  const isUserRacker = isRacker();
  const domain = useParams()?.domain;


  const { query: { data: grantsResult } } = useQueryGetGCPAccessGrants();
  const accessGrants = grantsResult?.accessGrants || [];
  const { accounts, search, query } = useAccountsMatchingSearch();
  const { accounts: typeFiltered } = useAccountsMatchingSelectedAccountType({ accounts });
  const { accounts: statusFiltered } = useAccountsMatchingSelectedAccountStatus({ accounts });
  const filteredAccounts = _.intersection(typeFiltered, statusFiltered);
  const data = mergeAccessGrants(filteredAccounts, accessGrants);

  const actions = (
    <div className="rsd-account-list__actions d-flex align-items-stretch w-100 justify-content-between">
      <AddAccountTrigger
        addAccountTypes={isUserRacker ? CONSTANTS.ADD_ACCOUNTS_RACKER : CONSTANTS.ADD_ACCOUNTS_CUSTOMER}
        loginType={isUserRacker? 'racker':'customer'}
        roles={session?.session?.identity?.access?.user?.roles}
        domainId={domain}
      />
      <div className="d-flex align-items-stretch flex-wrap gap-4">
        <b.ButtonGroup size="md" aria-label="View Type Selector" className="">
          <b.Button variant={ViewComponent === AccountCardView ? 'primary' : 'outline-primary'} onClick={() => setViewComponentType('card')} className="rsd-select-view-type--card"><Grid size={20} /></b.Button>
          <b.Button variant={ViewComponent === AccountTableView ? 'primary' : 'outline-primary'} onClick={() => setViewComponentType('table')} className="rsd-select-view-type--table"><List size={20} /></b.Button>
        </b.ButtonGroup>
        <SearchForm search={search} />
        <FilterTrigger allAccounts={accounts} filteredAccounts={filteredAccounts} />
      </div>
    </div>
  );

  return (
    <Page.MainBodySection action={actions}>
      <div>
        <ViewComponent query={query} data={data}/>
      </div>
    </Page.MainBodySection>
  );
};

const AccountListActiveByDefault = () => {
  return (
    <DefaultSearchParams params={{ status: 'Active' }}>
      <AccountList />
    </DefaultSearchParams>
  );
};

export default AccountListActiveByDefault;
