import _ from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Page } from '@janus.team/janus-particles';
import { useFilterTaxonomy, useIsFilterSelected, useSelectedFilters, useFilterToggleSelection, filterLabel } from 'search/filter-taxonomy.js';
import { Helmet } from 'react-helmet';
import { usePreference } from 'preferences/index.js';
import { t } from 'translations/index.js';
import * as b from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import ChevronUp from '@rss-engineering/fabric-assets/dist/icons/ChevronUp';
import ChevronDown from '@rss-engineering/fabric-assets/dist/icons/ChevronDown';
import SlidersSimple from '@rss-engineering/fabric-assets/dist/icons/SlidersSimple';
import CheckPlain from '@rss-engineering/fabric-assets/dist/icons/CheckPlain';

import { accountTypeDescriptorForAccount, detailsUrlForAccount, hasDetailsLink, searchCustomers, getFriendlyStatus, accountStatusClass } from 'customer/data.js';
import { DependencyRender } from 'dependencies/index.js';
import LinkDropdown from 'common/link-dropdown';

const FilterDropdownItem = ({ label, value }) => {
  const isSelected = useIsFilterSelected(value);
  const toggleSelection = useFilterToggleSelection(value);
  return (
    <b.Dropdown.Item as="div" role="option" onClick={toggleSelection} value={value} className="mb-2 d-flex align-items-center">
      {isSelected ? <span className="me-2"><CheckPlain className="check-svg" size={14}/></span>: <span className="me-3"/> }
      {' '}
      {label}
    </b.Dropdown.Item>
  );
};
const FilterDropdownGroup = ({ label, filters }) => (
  <>
    <b.Dropdown.Header>{label}</b.Dropdown.Header>
    {_.map(filters, ({ value, label }) => (<FilterDropdownItem key={value} label={label} value={value} />))}
  </>
);

const FilterDropdownItems = () => {
  const filters = useFilterTaxonomy();
  return (
    <b.Dropdown.Menu>
      {_.map(filters, (filter) => (filter?.filters ? (
        <FilterDropdownGroup key={filter?.value} {...filter} />
      ) : (
        <FilterDropdownItem key={filter?.value} {...filter} />
      )))}
    </b.Dropdown.Menu>
  );
};

const FilterDropdownTrigger = () => {
  const filters = useSelectedFilters();
  const filterDropdownLabel = _.get(_.find([
    [filters?.length === 1, filterLabel(_.head(filters))],
    [filters?.length === 0, t('Filter')],
    [true, `${filters.length} Filters...`]
  ], 0), 1);
  return (
    <b.Dropdown.Toggle variant="outline-primary" className="rsd-customer-search__filter-button" type="button">
      <SlidersSimple size={20}/>{' '}<span>{filterDropdownLabel}</span>
    </b.Dropdown.Toggle>
  );
};

const FilterDropdown = () => (
  <b.Dropdown>
    <FilterDropdownTrigger />
    <FilterDropdownItems />
  </b.Dropdown>
);

const SearchForm = ({ initialValue }) => {
  const locationSearch = useLocation()?.search;
  const filters = useSelectedFilters();
  const history = useHistory();
  const [inputValue, setInputValue] = React.useState(initialValue);
  const onSearch = (event) => {
    _.invoke(event, 'preventDefault');
    const query = new URLSearchParams(locationSearch);
    query.set('search', inputValue);
    query.set('filter-by', filters.join(','));
    history.push({ search: `?${query.toString()}` });
  };
  const placeholder = t('Search by Account Name or Account Number');
  const onChange = ({ target: { value } }) => setInputValue(value);
  const rootRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!rootRef.current) {
      return;
    }
    _.invoke(rootRef.current.querySelector('input[name="customerSearch"]'), 'focus');
  }, [rootRef]);


  return (
    <div ref={rootRef}>
      <b.Form name="customerSearch" onSubmit={onSearch}>
        <div className="rsd-customer-search-container">
          <b.InputGroup>
            <FilterDropdown />
            <b.FormControl {...{ value: inputValue, name: 'customerSearch', placeholder, onChange }} />
            <b.Button variant="primary" type="submit">{t('Search')}</b.Button>
          </b.InputGroup>
        </div>
      </b.Form>
    </div>
  );
};

const CustomerLink = ({ result }) => {
  const filters = useSelectedFilters();
  const typeFilters = _.filter(filters, _.unary(_.partialRight(_.startsWith, 'type:')));
  const types = _.map(typeFilters, _.unary(_.partialRight(_.replace, 'type:', '')));
  return (
    <Link className="display-name" to={`/racker/customers/${result?.domain}?types=${types.join(',')}`}>
      {result?.displayName}<br />
    </Link>
  );
};

const CustomerAccountsTableRow = ({ result, domain }) => {
  const nameWithLink = (
    <a href={detailsUrlForAccount(result)}>
      <div ><strong className="account-link">{result.name}</strong></div>
    </a>
  );

  const nameWithoutLink = (
    <div><strong className="account-link">{result.name}</strong></div>
  );

  const statusColor = accountStatusClass(result?.status);

  return (
    <tr key={result?.id}>
      <td className="rsd-customer-search-row__name particles-table__table-cell--dualline">
        {hasDetailsLink(result) ? nameWithLink : nameWithoutLink}
        <span className="rsd-customer-search-result-sub-list__domain">{result?.id}</span>
      </td>
      <td className="rsd-customer-search-row__type">
        <span>
          {accountTypeDescriptorForAccount(result)}
        </span>
      </td>
      <td className="rsd-customer-search-row__status">{
        <Badge
          bg={statusColor}
          pill
          text={statusColor}
          variant="light"
        >
          {getFriendlyStatus(result?.status)}
        </Badge>
      }</td>
    </tr>
  );
};

const CustomerAccountsTable = ({ results, domain }) => {
  return (
    <div className="rsd-customer-search-result-list__customer-accounts-table">
      <b.Table size={null} responsive>
        <thead>
          <tr>
            <th className="rsd-customer-search-row__name">Account</th>
            <th className="rsd-customer-search-row__type">Type</th>
            <th className="rsd-customer-search-row__status">Status</th>
          </tr>
        </thead>
        <tbody className="rsd-customer-table-accounts">
          {_.map(_.orderBy(results, (result) => _.toLower(result?.name)), (result) => (
            <CustomerAccountsTableRow result={result} domain={domain} key={result?.id} />
          ))}
        </tbody>
      </b.Table>
    </div>
  );
};


const AccountCounts = ({ results, ...rest }) => {
  const groupedByAccountType = _.groupBy(_.orderBy(results, accountTypeDescriptorForAccount), accountTypeDescriptorForAccount);
  return (
    <span className="d-flex flex-wrap gap-2 rsd-customer-search-result-list__item-header__account-counts" {...rest}>
      {_.map(groupedByAccountType, (accounts, label) => (
        <Badge key={label} bg="light" text="light" className="rsd-customer-search-result-list__item-header__account-type-count">
          {label} <Badge bg="light" pill text="light" className="ms-2 badge-sm"><strong>{accounts?.length}</strong></Badge>
        </Badge>
      ))}
    </span>
  );
};

const CustomerHeader = ({ results, domain, toggleExpanded, expanded }) => {
  return (
    <div className="rsd-customer-search-result-list__item-header">
      <div className="rsd-customer-search-result-list__item-header__customer d-flex flex-wrap gap-2">
        <div className="d-flex flex-wrap w-100 link-container">
          <LinkDropdown domain={domain} />
          <CustomerLink {...{ result: _.head(results) }} />
          <span className="rsd-customer-search-result-list__item-header__domain">{domain}</span>
        </div>
        <AccountCounts {...{ results, onClick: toggleExpanded }} />
      </div>
      <span onClick={toggleExpanded} className="ms-auto rsd-customer-search-result-list__item-header__toggle">
        {expanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </span>
    </div>
  );
};

const CustomerResults = ({ results, totalResults, preferExpanded }) => {
  const [expandedValue, setExpanded] = React.useState(null);
  const expanded = _.isBoolean(expandedValue) ? expandedValue : ((totalResults < 2) || preferExpanded || expandedValue);
  const toggleExpanded = React.useCallback((event) => {
    _.invoke(event, 'preventDefault');
    setExpanded(!expanded);
  }, [setExpanded, expanded]);
  const domain = _.get(results, '0.displayDomain');

  return (
    <b.ListGroup className="mb-3">
      <b.ListGroupItem data-testid="listitem">
        <CustomerHeader {...{ results, domain, toggleExpanded, expanded }} />
        {expanded && (<CustomerAccountsTable {...{ results, domain }} />)}
      </b.ListGroupItem>
    </b.ListGroup>
  );
};

const SearchResults = ({ groupedByCustomer, totalResults, preferExpanded }) => {
  return (
    <ul className="rsd-customer-search-result-list">
      {_.map(groupedByCustomer, (results) => (<CustomerResults {...{ preferExpanded, totalResults, results }} key={_.get(results, '0.displayDomain')} />))}
    </ul>
  );
};

const CustomerSearch = () => {
  const [preferExpanded, setExpandPreference] = usePreference('expandSearchResults', true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = _.trim(queryParams.get('search') || '');
  const filters = useSelectedFilters();

  const query = useQuery(['homeSearch', { search, filters }], searchCustomers, { enabled: !!_.trim(search) });
  const results = query.data;
  const groupedByCustomer = _.orderBy(_.groupBy(results, 'displayDomain'), '0.displayName');

  const changeExpandedPreference = ({ target: { checked } }) => {
    setExpandPreference(!!checked);
  };

  return (
    <>
      <Helmet>
        <title>{t('Customer Search')} | {t('Rackspace')}</title>
      </Helmet>
      <Page.MainHeader title={t('Customer Search')} withBackground withSections>
        <SearchForm initialValue={search} />
      </Page.MainHeader>
      <Page.MainBody>
        <Page.MainBodySection>
          <DependencyRender dependencies={[query]}>
            <DependencyRender.Initial>
              <div className="rsd-search-result-placeholder">{t('Start by searching for a Rackspace Account')}</div>
            </DependencyRender.Initial>
            <DependencyRender.Loading />
            <DependencyRender.Cached>
              <div className="rsd-search-result-header">
                <span>{groupedByCustomer?.length} Result{(groupedByCustomer?.length === 1) ? '' : 's'} for <strong>"{search}"</strong></span>
                <div className="rsd-search-result-header__actions fs-6">
                  <b.Form.Check
                    type="checkbox"
                    className="form-switch"
                    label={t('Expand Account List')}
                    id="expandSearchResults"
                    name="expandSearchResults"
                    checked={!!preferExpanded}
                    onChange={changeExpandedPreference}
                  />
                </div>
              </div>
              {results?.length > 99 &&
                <b.Alert variant="info">{t('Searching is limited to 100 accounts. You may need to search with more specific terms.')}</b.Alert>
              }
              <SearchResults {...{ groupedByCustomer, totalResults: groupedByCustomer?.length, preferExpanded }} />
            </DependencyRender.Cached>
          </DependencyRender>
        </Page.MainBodySection>
      </Page.MainBody>
    </>
  );
};

export default CustomerSearch;
