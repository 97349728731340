import React from 'react';
import _ from 'lodash';
import { t } from 'translations/index.js';
import * as b from 'react-bootstrap';

import CardViewSkeleton from 'common/card-view-skeleton.js';
import ConsoleButton from 'common/console-button';

import * as customerData from 'customer/data.js';
import { ServiceBlocks } from 'customer/service-blocks.js';
import { useQueryGetCustomerAccountMetadata } from 'customer/queries.js';
import LinkDropdown from 'common/link-dropdown';

const AccountCardHeader = ({ account }) => {
  const { query: detailsQuery } = useQueryGetCustomerAccountMetadata(account);
  const accountMeta = _.merge(account, { metadata: detailsQuery?.data?.metadata });
  const detailsUrl = customerData.detailsUrlForAccount(accountMeta, global.isRacker);
  const accountType = customerData.accountTypeDescriptorForAccount(account);

  const headerWithLink = (
    <b.Card.Header className="py-3">
      <b.Card.Title
        title={account.name}
        className="d-flex justify-content-between align-items-center h5 gap-3 overflow-visible"
      >
        <div>
          <span className="link-container"><LinkDropdown domain={account.domain}/></span>
          <a className="account-card-name" href={detailsUrl}>
            {account.name}
          </a>
        </div>
      </b.Card.Title>
      <b.Card.Subtitle>
        <span className="particles-subdued particles-subdued--small">{accountType}</span>
      </b.Card.Subtitle>
    </b.Card.Header>
  );

  const headerWithoutLink = (
    <b.Card.Header className="py-3">
      <b.Card.Title>
        <span className="link-container"><LinkDropdown domain={account.domain}/></span>
        {account.name}
      </b.Card.Title>
      <b.Card.Subtitle>
        <span className="particles-subdued particles-subdued--small">{accountType}</span>
      </b.Card.Subtitle>
    </b.Card.Header>
  );

  return (account?.status === 'Active') ? headerWithLink : headerWithoutLink;
};

const AccountCardFooter = ({ account }) => {
  if (!customerData.hasConsoleLink(account)) {
    return null;
  }

  return (
    <b.Card.Footer className="py-3">
      <ConsoleButton account={account} className="w-100"></ConsoleButton>
    </b.Card.Footer>
  );
};

const AccountCard = ({ account }) => {
  const statusColor = customerData.accountStatusClass(account);
  return (
    <b.Card>
      <AccountCardHeader {...{ account }} />
      <b.Card.Body>
        <section className="d-flex justify-content-between align-items-start">
          <dl>
            <dt className="mb-1">{t('ID')}</dt>
            <dd className="account-card-id">{account?.id}</dd>
            <dt className="mb-1 mt-4">{t('Service Levels')}</dt>
            <dd><ServiceBlocks account={{ ...account, isServiceLevel: true }} /></dd>
            {(account?.serviceLevel === 'serviceblocks') &&
              (<>
                <dt className="mb-1 mt-4">{t('Service Blocks')}</dt>
                <dd><ServiceBlocks account={{ ...account, isServiceLevel: false }} /></dd>
              </>)
            }
          </dl>
          <b.Badge
            bg={statusColor}
            pill
            text={statusColor}
            variant="light"
          >
            {customerData.getFriendlyStatus(account?.status)}
          </b.Badge>
        </section>
      </b.Card.Body>
      <AccountCardFooter {...{ account }} />
    </b.Card>
  );
};

const AccountCardView = ({ query, data }) => {
  return query.isLoading ? (<CardViewSkeleton />) : (
    <div className="fabric-auto-grid">
      {_.orderBy(data, ['name']).map((account) => (<AccountCard {...{ account }} key={account?.id} />))}
    </div>
  );
};

export default AccountCardView;
