import particles from '@janus.team/janus-particles';
import { messagesFor } from './messages.js';

export const customFormats = () => ({
  number: {
    janusMoneyUsd: { style: 'currency', currency: 'USD' }
  },
  date: {
    janusLongMonthYearUtc: { year: 'numeric', month: 'long', timeZone: 'utc' },
    janusLongMonthYear: { year: 'numeric', month: 'long' }
  }
});

export const particlesConfiguration = () => ({
  messages: messagesFor(particles.translations.resolveLocale()),
  customFormats: customFormats()
});

export const t = (...args) => particles.translations.t(particlesConfiguration(), ...args);

export const translations = { t, messagesFor, particlesConfiguration, customFormats };

export default translations;