import { proxyFetch as copilotProxyFetch } from '@janus.team/janus-copilot';
import { copilotConfiguration } from 'config/copilot.js';
import { isRacker } from 'config/index.js';
import _ from 'lodash';

export const proxyFetch = (url = '/', fetchOptions = {}, copilotOptions = {}) => {
  const baseUrl = copilotConfiguration()?.baseUrl;
  return copilotProxyFetch(
    `${baseUrl}/ui-proxy/${isRacker() ? 'r' : 'c'}/${encodeURIComponent(url)}`,
    _.merge(_.pick(copilotConfiguration(), ['credentials']), fetchOptions),
    _.merge(copilotConfiguration(), { racker: isRacker() }, copilotOptions)
  );
};
