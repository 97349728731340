import React from 'react';
import { useHistory } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { t } from '../translations/index.js';

export const BreadCrumb = ({ isRacker, accountName, domain }) => {
  const history = useHistory();
  const goToAccountsPage = () => {
    if (isRacker) {
      history.push(`/racker/customers/${domain}`);
    } else {
      history.push('/');
    }
  };
  return (
    <Breadcrumb className="breadCrumb">
      <Breadcrumb.Item onClick={goToAccountsPage} data-testid="breadcrumb-accounts">
        {t('Accounts')}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>
        {accountName}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};
