import _ from 'lodash';
import React from 'react';

export const preferenceKey = (key) => _.get({
  expandSearchResults: 'preference:expandSearchResults',
  viewType: 'preference:viewType'
}, key);

export const usePreference = (name, defaultValue = null) => {
  const [value, setValue] = React.useState(() => {
    const value = global.localStorage.getItem(preferenceKey(name)) ?? defaultValue;

    return (value === 'false') ? false : value;
  });
  const setter = React.useCallback((value) => {
    setValue(value);
    global.localStorage.setItem(preferenceKey(name), value);
  }, [setValue, name]);
  window.DD_RUM?.onReady(function() {
    window.DD_RUM.addAction('viewType', {
      value: value
    });
  });

  return [value, setter];
};

export const useViewTypePreference = (views, defaultView) => {
  const [type, setter] = usePreference('viewType');
  return [_.get(views, type, defaultView), setter];
};
