import { Dropdown, NavDropdown } from 'react-bootstrap';
import Bars from '@rss-engineering/fabric-assets/dist/icons/Bars';
import { externalLinksNames, getExternalLink } from './constants';


const LinkDropdown = ({domain})=>(
  <NavDropdown
    title={<Bars size={16} />}
    id="nav-dropdown"
    className="d-inline-block me-3 text-primary"
  >
    <Dropdown.Item disabled>View</Dropdown.Item>
    {externalLinksNames.map((name) => (
      <Dropdown.Item
        key={name}
        href={getExternalLink(name, domain)}
        target="_blank"
      >
        {name}
      </Dropdown.Item>
    ))}
  </NavDropdown>
);

export default LinkDropdown;
