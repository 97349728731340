import _ from 'lodash';
import React from 'react';

import { t } from 'translations/index.js';
import * as customerData from 'customer/data.js';
import { useMutationSetGCPAccessGrant } from 'customer/queries.js';
import Button from 'react-bootstrap/Button';
import ArrowUpRightFromSquare from '@rss-engineering/fabric-assets/dist/icons/ArrowUpRightFromSquare';
import Login from '@rss-engineering/fabric-assets/dist/icons/Login';

const GCPRequestAccessButton = ({ account, asIcon, className }) => {
  const [ setAccessGrant, isLoading ] = useMutationSetGCPAccessGrant();
  const disabled = !(/aviator|serviceblocks/i).test(account.serviceLevel);
  const onClick = () => isLoading || disabled || setAccessGrant(account.id);

  const loadingIndicator = (content) => isLoading ? ( 
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  ) : content;

  if (asIcon) {
    return <a className={`${className} ${disabled ? 'disabled' : ''}`} href="#disabled" disabled={disabled} onClick={onClick} title="Request Access"><Login size={20} className="login-svg"/></a>;
  }
  return <Button variant="outline-primary" className={className} as="a" disabled={disabled} onClick={onClick}>{loadingIndicator(t('Request Access'))}</Button>;
};

const ConsoleButton = ({ account, className, asIcon = false }) => {

  const handleConsoleClick = () => {
    window.DD_RUM.onReady(function() {
      window.DD_RUM.addAction('launch-console-account-type', {
        accountType: account.type
      });
    });
  };

  if (account.type === 'MANAGED_GCP' && global.isRacker && !_.some(account?.accessGrants)) {
    return <GCPRequestAccessButton className={className} asIcon={asIcon} account={account}></GCPRequestAccessButton>;
  }

  if (asIcon) {
    return <a className={className} href={customerData.consoleUrlForAccount(account, global.isRacker)} rel="noreferrer" target="_blank" title="Console"><Login size={20} className="login-svg"/></a>;
  }
  return <Button as="a" variant="outline-primary" onClick={handleConsoleClick} className={`${className} d-flex align-items-center justify-content-center`} href={customerData.consoleUrlForAccount(account, global.isRacker)} target="_blank"><span className="me-2">{t('Launch Console')}</span>  <ArrowUpRightFromSquare/></Button>;
};

export default ConsoleButton;
