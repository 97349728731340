import { isRacker } from 'config/index.js';
import { baseUrl } from 'config';

const CONSTANTS = {
  ADD_ACCOUNTS_RACKER: [
    // NO AWS
    { name: 'AZURE', link: 'https://manage.rackspace.com/azure/racker/rackspace-accounts/DOMAIN_ID/subscription/new' },
    { name: 'GCP', link: 'https://manage.rackspace.com/gcp/racker/customers/DOMAIN_ID/projects/new' },
    { name: 'VMWARE', link: 'https://manage.rackspace.com/vmware/racker/DOMAIN_ID' }
  ],
  ADD_ACCOUNTS_CUSTOMER: [
    { name: 'AWS', link: 'https://manage.rackspace.com/aws/accounts/add' },
    { name: 'AZURE', link: 'https://manage.rackspace.com/azure/subscription/new' },
    { name: 'GCP', link: 'https://manage.rackspace.com/gcpv2/projects?import=open' }
    // NO VWWARE
  ]
};
export const externalLinksNames= ['Resources', 'Tickets', 'Services'];

export const getExternalLink = (name, domain) => {
  const currentBasePath = baseUrl();
  const isUserRacker = isRacker();
  switch (name) {
  case 'Resources':
    return `${currentBasePath}/resources/${
      isUserRacker ? 'racker/' : ''
    }${domain}`;
  case 'Tickets':
    return `${currentBasePath}/tickets/${isUserRacker ? 'racker/' : ''}${domain}`;
  case 'Services':
    return `${currentBasePath}/services${isUserRacker ? '/racker' : ''}`;
  default:
    return '';
  }
};

export default CONSTANTS;
