import _ from 'lodash';
import { isDev, isLocal } from 'config/index.js';
import { proxyFetch } from 'data/proxy.js';

const gcpBaseUrl = () => (isDev() || isLocal()) ? 'https://gcp.api.dev.manage.rackspace.com' : 'https://gcp.api.manage.rackspace.com';

export const getGCPAccessGrants = () => {
  const url = `${gcpBaseUrl()}/v1alpha/accessGrants`;
  return proxyFetch(url, { headers: {
    Accept: 'application/json'
  }})
    .then(_.method('parseJson'));
};

export const setGCPAccessGrants = (projectId) => {
  const url = `${gcpBaseUrl()}/v1alpha/accessGrants`;
  return proxyFetch(url, {
    method: 'POST',
    body: JSON.stringify({ projectId }),
    headers: {
      Accept: 'application/json'
    }
  })
    .then(_.method('parseJson'));
};
