import _ from 'lodash';
import React from 'react';
import { Table } from '@janus.team/janus-particles';
import * as b from 'react-bootstrap';

export const LoadingBlock = () => (
  <div className="rsd-loading-indicator--block">
    <b.Spinner animation="border" variant="dark" />
  </div>
);

export const LoadingTableBody = ({ columns, rows = 10 }) => (
  <Table.Body>
    {_.map(_.range(rows?.length || rows), (row) => (
      <Table.Row key={row}>
        {_.map(_.range(columns?.length || columns), (column) => (<Table.Cell compact key={column} className="rsd-table-skeleton-body-cell"><div className="rsd-skeleton__placeholder">&nbsp;</div></Table.Cell>))}
      </Table.Row>
    ))}
  </Table.Body>
);

export const LoadingTable = ({ columns, rows = 10 }) => (
  <div className="rsd-loading-indicator--table rsd-loading-indicator--skeleton">
    <b.Spinner animation="border" variant="dark" size={'sm'} />
    <Table.Wrapper>
      <Table>
        <Table.Header>
          <Table.Row>
            {_.map(columns, (column) => (<Table.Cell header compact key={column} className="rsd-table-skeleton-header-cell">{column}</Table.Cell>))}
          </Table.Row>
        </Table.Header>
        <LoadingTableBody columns={columns} rows={rows} />
      </Table>
    </Table.Wrapper>
  </div>
);
