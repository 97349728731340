import _ from 'lodash';
import { audience, isRacker } from 'config/index.js';
import { proxyFetch } from 'data/proxy.js';

const baseUrlForAudience = () => {
  return _.get({
    customer: 'https://customer.api.rackspacecloud.com/v1',
    racker: 'https://proxy.api.manage.rackspace.com/customer-admin/v3'
  }, audience());
};

export const getCustomerInformation = (index, domainId) => {
  return listCustomerAccountsForDomain(index, domainId)
    .then((accounts) => _.merge({ domain: domainId }, _.find(accounts, { id: domainId.replace('dedicated:', '') })));
};

export const listCustomerAccountsForDomain = (index, domainId) => {
  const url = isRacker() ?
    `${baseUrlForAudience()}/customer_accounts?limit=1000&domain=${domainId}` :
    `${baseUrlForAudience()}/customer_accounts?limit=1000`;
  return proxyFetch(url, { headers: { Accept: 'application/json' } })
    .then(_.method('parseJson'))
    .then(_.property('customerAccount'));
};

export const getCustomerAccountDetails = (index, domainId, accountType, accountId) => {
  const url = `https://proxy.api.manage.rackspace.com/customer-admin/v3/customer_accounts/${accountType}/${accountId}/detail`;
  return proxyFetch(url, { headers: { Accept: 'application/json' } })
    .then(_.method('parseJson'));
};

export const getCustomerAccountMetadata = (index, domainId, accountType, accountId) => {
  const url = `${baseUrlForAudience()}/customer_accounts/${accountType}/${accountId}/metadata`;
  return proxyFetch(url, { headers: { Accept: 'application/json' } })
    .then(_.method('parseJson'));
};
