import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

export function appendDefaultSearchParams({ defaults = {}, search }) {
  const queryParams = new URLSearchParams(search);
  const missingParams = Object.keys(defaults).filter((key) => !queryParams.has(key));

  missingParams.forEach((key) => {
    queryParams.set(key, defaults[key]);
  });

  return isEmpty(missingParams) ? search : queryParams.toString();
}

export function useDefaultSearchParams({ defaults, search }) {
  return useMemo(() => {
    const searchWithDefaults = appendDefaultSearchParams({ defaults, search });
    
    return searchWithDefaults !== search ? searchWithDefaults : null;
  }, [defaults, search]);
}

export default function DefaultSearchParams({params, children}) {
  const location = useLocation();
  const search = useDefaultSearchParams({ defaults: params, search: location?.search });

  if (search) {
    return <Redirect to={{ ...location, search }} />;
  }

  return <>{children}</>;
}
