import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { datadogRum } from '@datadog/browser-rum';

import { AppWithRouter } from 'app.js';

import 'react-tippy/dist/tippy.css';
import '@janus.team/janus-particles/dist/esm/janus-particles.css';
import './particles.css';
import './index.css';
import './index.scss';

import * as config from 'config/index.js';

const DevTools = () => {
  const search = global.location.search;
  const forcedViaQueryString = (/react-query-devtools/i).test(`${search}`);
  return (config.isLocal() || forcedViaQueryString) ? (<ReactQueryDevtools />) : null;
};

const targetContainer = () => document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={config.queryCache()}>
      <AppWithRouter />
      <DevTools />
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  targetContainer()
);

datadogRum.init({
  applicationId: 'c3fc2db1-6f69-4e59-9355-63c4fb2064a7',
  clientToken: 'pub322419ddb28ef1a974f28b417cbc47f9',
  service: 'solutions-dashboard',
  env: config.env(),
  trackInteractions: true
});
