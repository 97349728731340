import React from 'react';
import _ from 'lodash';
import { LoadingBlock } from 'loading/index.js';

export const dependenciesContext = React.createContext(null);
export const useDependencies = () => React.useContext(dependenciesContext)?.dependencies || [];

const render = (fnOrComponent) => _.isFunction(fnOrComponent) ? fnOrComponent() : fnOrComponent;

export const DependencyRenderInitial = ({ children }) => {
  const dependencies = useDependencies();
  return (!_.some(dependencies, _.property('isSuccess')) && !_.some(dependencies, _.property('isLoading'))) ? (
    <>{render(children)}</>
  ) : null;
};
export const DependencyRenderLoading = ({ children }) => {
  const dependencies = useDependencies();
  return (!_.every(dependencies, _.property('isSuccess')) && _.some(dependencies, _.property('isLoading'))) ? (
    <>{render(children) || (<LoadingBlock />)}</>
  ) : null;
};
export const DependencyRenderCached = ({ children }) => {
  const dependencies = useDependencies();
  return _.every(dependencies, _.property('isSuccess')) ? (<>{render(children)}</>) : null;
};

export const DependencyRender = ({ dependencies, children }) => (
  <dependenciesContext.Provider value={{ dependencies }}>
    {render(children)}
  </dependenciesContext.Provider>
);

DependencyRender.Initial = DependencyRenderInitial;
DependencyRender.Loading = DependencyRenderLoading;
DependencyRender.Cached = DependencyRenderCached;
