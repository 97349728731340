import _ from 'lodash';
import React from 'react';
import { t } from 'translations/index.js';
import * as b from 'react-bootstrap';

import * as customerData from 'customer/data.js';
import { LoadingTableBody } from 'loading/index.js';
import { ServiceBlocks } from 'customer/service-blocks.js';
import ConsoleButton from 'common/console-button';
import LinkDropdown from 'common/link-dropdown';
import { useQueryGetCustomerAccountMetadata } from 'customer/queries.js';

const AccountTableSkeleton = () => (<LoadingTableBody columns={5} />);

const buildNameForAccount = (account) => {
  return (
    <div>
      <div className="d-flex align-items-center">
        <span><LinkDropdown domain={account.domain}/></span>
        {customerData.hasDetailsLink(account) ? (
          <a href={customerData.detailsUrlForAccount(account, global.isRacker)}>
            <strong>{account?.name}</strong>
          </a>
        ) : (
          <strong>{account?.name}</strong>
        )}
      </div>
    </div>
  );
};

const buildConsoleLinkForAccount = (account) => {
  if (!customerData.hasConsoleLink(account)) {
    return null;
  }

  return (
    <ConsoleButton asIcon account={account} as={'a'}></ConsoleButton>
  );
};

const AccountTableRow = ({ account }) => {
  const id = account?.id;
  const type = customerData.accountTypeDescriptorForAccount(account);
  const statusColor = customerData.accountStatusClass(account);
  const { query: detailsQuery } = useQueryGetCustomerAccountMetadata(account);
  const accountMeta = _.merge(account, { metadata: detailsQuery?.data?.metadata });

  return (
    <tr>
      <td className="particles-table__table-cell--dualline">
        {buildNameForAccount(accountMeta)}
        <div>{id}</div>
      </td>
      <td>{type}</td>
      <td>
        <ServiceBlocks account={{ ...account, isServiceLevel: true }} />
      </td>
      <td>
        <ServiceBlocks account={{ ...account, isServiceLevel: false }} />
      </td>
      <td>
        <b.Badge
          bg={statusColor}
          pill
          text={statusColor}
          variant="light"
        >
          {customerData.getFriendlyStatus(account?.status)}
        </b.Badge>
      </td>
      <td>{buildConsoleLinkForAccount(account)}</td>
    </tr>
  );
};

const AccountTableBody = ({ accounts }) => (
  <tbody>
    {_.orderBy(accounts, ['name']).map((account) => (<AccountTableRow {...{ account }} key={account?.id} />))}
  </tbody>
);

const AccountTableView = ({ query, data }) => {
  return (
    <b.Table size={null} responsive className="rsd-table--account-table-view">
      <thead>
        <tr>
          <th>{t('Account')}</th>
          <th>{t('Type')}</th>
          <th>{t('Service Levels')}</th>
          <th>{t('Service Blocks')}</th>
          <th>{t('Status')}</th>
          <th></th>
        </tr>
      </thead>
      {query.isLoading ? (<AccountTableSkeleton />) : (<AccountTableBody accounts={data} />)}
    </b.Table>
  );
};

export default AccountTableView;
