import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from 'translations/index.js';

const allAccounts = { label: t('All Accounts'), value: 'all' };

const awsAccounts = { label: t('AWS Accounts'), value: 'type:FANATICAL_SUPPORT_FOR_AWS' };
const azureSubscriptions = { label: t('Azure Subscriptions'), value: 'type:AZURE' };
const gcpProjects = { label: t('GCP Projects'), value: 'type:MANAGED_GCP' };
const managedHosting = { label: t('Managed Hosting'), value: 'type:MANAGED_HOSTING' };
const openStackCloud = { label: t('OpenStack Public Cloud'), value: 'type:CLOUD' };
const rsvcAccounts = { label: t('Rackspace Services for VMware'), value: 'type:RSVC' };
const vmwarePrivateCloud = { label: t('SDDC Business / Enterprise'), value: 'type:RPC_V' };
const vmcAccounts = { label: t('VMware Cloud on AWS'), value: 'type:MANAGED_VMC' };

const byType = { label: t('Type'), value: 'type', filters: [
  awsAccounts, azureSubscriptions, gcpProjects, managedHosting, openStackCloud, rsvcAccounts, vmcAccounts, vmwarePrivateCloud
] };

const filterCatalog = [allAccounts, byType];
const allFilters = [allAccounts, ...byType?.filters];

export const filterLabel = (value) => _.get(_.find(allFilters, { value }), 'label');
export const useFilterTaxonomy = () => {
  return filterCatalog;
};

export const useSelectedFilters = () => {
  const location = useLocation();
  const selectedFiltersViaQueryString = _.compact(_.split((new URLSearchParams(location?.search)).get('filter-by'), ','));
  return _.without(selectedFiltersViaQueryString, 'all');
};

export const useIsFilterSelected = (value) => {
  const selectedFilters = useSelectedFilters();
  return _.some(selectedFilters) ? _.includes(selectedFilters, value) : (value === 'all');
};

export const useFilterToggleSelection = (value) => {
  const history = useHistory();
  const location = useLocation();

  const wasSelected = useIsFilterSelected(value);
  const selectedFilters = useSelectedFilters();
  const newFilters = wasSelected ? _.without(selectedFilters, value) : _.concat(selectedFilters, [value]);
  const normalizedNewFilters = _.includes(newFilters, 'all') ? [] : newFilters;

  const query = new URLSearchParams(location?.search);
  query.set('filter-by', normalizedNewFilters);

  return () => history.push({ search: `?${query.toString()}` });
};
